import React, { useState } from "react";

//styles
import "./Partners.scss";

//icons
import { GrClipboard } from 'react-icons/gr'
import { FcCheckmark } from 'react-icons/fc'

//content
import partners from "./partners.js";

const Partners = () => {
  const [visibleCode, setVisibleCode] = useState(null);
  const [copiedCode, setCopiedCode] = useState(null)

  const handleCopy = (code, partnerName) => {
    navigator.clipboard.writeText(code);
    setCopiedCode(partnerName);

    setTimeout(() => {
      setVisibleCode(null);
      setCopiedCode(null);
    }, 3000);
  };
  return (
    <div
      className="partners"
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-easing="ease-in-out"
    >
      <h2>Proud Partners</h2>
      <span className="hr-line"></span>{" "}
      <p>
        We are always looking to work with like-minded small businesses to
        improve our community’s health and wellness. Email Concierge Wellness
        for inquiries.
      </p>
      <div className="partner-card-container">
        {partners.map((partner) => (
          <div className="partner-wrapper" key={partner.name}>
            <div className="partner-card">
              <a href={partner.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={require(`../../${partner.logo}`)}
                  alt={partner.name}
                />
              </a>
            </div>

            <div className="partner-info">
              <a
                className="partner-name"
                href={partner.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {partner.name}
              </a>

              {partner.code && (
                <div className="coupon-container">
                  {partner.codeText && (
                    <div className="code-text">
                      <p>{partner.codeText}</p>
                    </div>
                  )}

                  {copiedCode === partner.name ? (
                    <button disabled className="copied-button"><FcCheckmark size={20}/>&nbsp;Copied!</button>
                  ) : (
                    <button
                      onClick={() =>
                        setVisibleCode(
                          visibleCode === partner.name ? null : partner.name
                        )
                      }
                    >
                      {visibleCode === partner.name ? "Hide Code" : "Show Code"}
                    </button>
                  )}

                  {visibleCode === partner.name && copiedCode !== partner.name && (
                    <span
                      className="coupon-code"
                      onClick={() => handleCopy(partner.code, partner.name)}
                    >
                      <GrClipboard size={15} />&nbsp;{partner.code}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
