const partners = [
  {
    name: "Praxis Yoga",
    logo: "assets/praxis.png",
    link: "https://www.pulsewellnessco.com/",
    codeText: "Get your first class FREE with our exclusive code",
    code: "CWPT",
  },
  {
    name: "Black Horse Ranch",
    logo: "assets/blackhorse.png",
    link: "https://www.blackhorseranchllc.com",
    codeText: "Get 10% off your first order at Black Horse",
    code: "WELLNESS",
  },
  {
    name: "Libin Web Development",
    logo: "assets/yellowkl.png",
    link: "https://libinwebdevelopment.com",
    codeText: "Mention this code to get $20 off your first year of hosting",
    code: "CWPT-20",
  },
];

export default partners;
